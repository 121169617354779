import React from "react";
import PropTypes from "prop-types";
import LeftSidebar from "./common/left-sidebar";
import PWReset from "./components/pw-reset";
import Container from "react-bootstrap/Container";

const qs = location.search;

function App() {
  const accountDetails = {
    resetToken: qs.slice(1),
    customerId: "",
    accountId: "",
    password: "",
    confPassword: "",
  };

  document.body.setAttribute(
    "data-layout-config",
    '{"leftSideBarTheme":"dark","layoutBoxed":false, "leftSidebarCondensed":false, "leftSidebarScrollable":false,"darkMode":false, "showRightSidebarOnStart": true}'
  );
  document.body.setAttribute("data-leftbar-theme", "dark");

  return (
    <div className="container-fluid">
      <LeftSidebar />
      <div className="content-page">
        <Container fluid className="no-gutters">
          <Container fluid className="no-gutters">
            <h1>Password Reset</h1>
            <PWReset accountData={accountDetails} disableSubmitButton={false} />
          </Container>
        </Container>
      </div>
    </div>
  );
}

App.propTypes = {
  accountData: PropTypes.object,
  disableSubmitButton: PropTypes.bool,
};

export default App;
