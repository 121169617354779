import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Jumbotron from "react-bootstrap/Jumbotron";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import InputGroup from "react-bootstrap/InputGroup";

const PWReset = (props) => {
  const passwordRegex = new RegExp(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!"#$%&'()+,-./:;<=>?[\\\]^_`{|}~])[A-Za-z\d!"#$%&'()+,-./:;<=>?[\\\]^_`{|}~]{12,}$/
  );
  const [loginInformationFormData, setLoginInformationFormData] = useState(
    props.accountData
  );
  const [enableSubmitButton, setEnableSubmitButton] = useState(true);
  const [isAccountNameValid, setIsAccountNameValid] = useState(false);
  const [isCustomerIdValid, setIsCustomerIdValid] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [doPasswordMatch, setDoPasswordMatch] = useState(false);
  const [accountNameInvalidMsg, setAccountNameInvalidMsg] = useState("");
  const [customerIdInvalidMsg, setCustomerIdInvalidMsg] = useState("");
  const [passwordInvalidMsg, setPasswordInvalidMsg] = useState("");
  const [passwordNoMatchMsg, setPasswordNoMatchMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  const hasValidAccountName = (accountName) => {
    if (!accountName?.trim()) {
      setAccountNameInvalidMsg("Account name cannot be blank.");
      return false;
    } else {
      setAccountNameInvalidMsg("");
      return true;
    }
  };

  const hasValidCustomerId = (customerId) => {
    if (!customerId?.trim()) {
      setCustomerIdInvalidMsg("Customer Id cannot be blank.");
      return false;
    } else {
      setCustomerIdInvalidMsg("");
      return true;
    }
  };

  const hasValidPassword = (password) => {
    setIsPasswordValid(true);
    if (
      password &&
      !passwordRegex.test(password)
    ) {
      setPasswordInvalidMsg("Password is not valid.");
      return false;
    }
    setPasswordInvalidMsg("");
    return true;
  };

  const passwordsMatch = (password, confPassword) => {
      if (password && hasValidPassword) {
        if (password !== confPassword ) {
          setPasswordNoMatchMsg("Passwords don't match.");
          return false;
        }
        setPasswordNoMatchMsg("");
        return true;
      }
  };

  useEffect(() => {
    setEnableSubmitButton(isButtonEnabled());
  });

  const isButtonEnabled = () => {
    return (
      isAccountNameValid &&
      isCustomerIdValid &&
      isPasswordValid &&
      doPasswordMatch
    );
  };

  const handleReset = (e) => {
    e.preventDefault();

    const url = process.env.REACT_APP_SET_ACCOUNT_PW_API_ENDPOINT;

    fetch(url, {
      method: "POST",
      header: { "Content-Type": "application/json" },
      body: JSON.stringify({
        password: loginInformationFormData.password,
        token: loginInformationFormData.resetToken,
        username: loginInformationFormData.accountId,
        companyid: loginInformationFormData.customerId,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.data.setAccountPassword) {
          setErrorMsg("");
          setSuccessMsg(
            "Success! The password for this account has been updated."
          );
          setEnableSubmitButton(false);
        } else {
          data.data.errors[0].message
            ? setErrorMsg(data.data.errors[0].message)
            : "There was a problem processing your request. Please try again or contact your Account Manager.";
        }
      })
      .catch(() => {
        setErrorMsg(
          "There was a problem processing your request. Please try again or contact your Account Manager"
        );
      });
  };

  const getPwRulesJsx = () => {
    return (
      <Container className="col border ml-0">
        <div className="bold-text">Requirements</div>
        <Row xs={1} md={2}>
          <Col id="requirements">
            <div className="modal-text">•Min 12 characters</div>
            <div className="modal-text">•1 Uppercase</div>
            <div className="modal-text">•1 Lowercase</div>
          </Col>
          <Col className="zero-padding-left">
            <div className="modal-text">•1 Number</div>
            <div className="modal-text">
              •1 Special character <br />
              &nbsp; &nbsp;(@ * Not Allowed)
            </div>
          </Col>
        </Row>
        <div className="edit-account-padding"></div>
      </Container>
    );
  };

  const getErrorAlertJSX = () => {
    return (
      <div className="alert alert-danger" role="alert">
        {errorMsg}
      </div>
    );
  };

  const getSuccessAlertJSX = () => {
    return (
      <div className="alert alert-success" role="alert">
        {successMsg}
      </div>
    );
  };

  return (
    <Jumbotron>
      {errorMsg && getErrorAlertJSX()}
      {successMsg && getSuccessAlertJSX()}
      <div className="container-fluid">
        <Form>
          <Form.Row>
            <Form.Group
              as={Col}
              md="6"
              className={!isAccountNameValid ? `invalid` : `valid`}
              controlId="formAccountID"
            >
              <Form.Label>Username</Form.Label>
              <Form.Control
                inline
                required
                readOnly={successMsg}
                type={`text`}
                isInvalid={!isAccountNameValid}
                placeholder="Enter Your User Account Name"
                className="edit-account-input inline-account-name"
                value={loginInformationFormData?.accountId ?? ""}
                onPaste={(e) => {
                  setIsAccountNameValid(hasValidAccountName(e.target.value));
                }}
                onChange={(e) => {
                  const newFormData = { ...loginInformationFormData };
                  newFormData.accountId = e.target.value;
                  setLoginInformationFormData(newFormData);
                  setIsAccountNameValid(hasValidAccountName(e.target.value));
                }}
              ></Form.Control>
              {!isAccountNameValid && (
                <Form.Control.Feedback type="invalid">
                  {accountNameInvalidMsg}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group as={Col} md="6" controlId="formCustomerID">
              <Form.Label>Company ID</Form.Label>
              <Form.Control
                inline
                required
                readOnly={successMsg}
                type={`text`}
                isInvalid={!isCustomerIdValid}
                placeholder="Enter Your Customer ID"
                className="edit-account-input inline-account-name"
                value={loginInformationFormData?.customerId ?? ""}
                onPaste={(e) => {
                  setIsCustomerIdValid(hasValidCustomerId(e.target.value));
                }}
                onChange={(e) => {
                  const newFormData = { ...loginInformationFormData };
                  newFormData.customerId = e.target.value;
                  setLoginInformationFormData(newFormData);
                  setIsCustomerIdValid(hasValidCustomerId(e.target.value));
                }}
              ></Form.Control>
              {!isCustomerIdValid && (
                <Form.Control.Feedback type="invalid">
                  {customerIdInvalidMsg}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} md="6">
              <Form.Label>New Password</Form.Label>
              <InputGroup>
                <FormControl
                  placeholder="New Password"
                  aria-label="New Password"
                  type={`password`}
                  readOnly={successMsg}
                  onFocus={(e) => (e.target.type = "text")}
                  onBlur={(e) => {e.target.type = "password";}}
                  className="edit-account-input"
                  isInvalid={!isPasswordValid}
                  value={
                    loginInformationFormData &&
                    loginInformationFormData.password &&
                    loginInformationFormData.password.indexOf(
                      "pbkdf2_sha256$"
                    ) != 0
                      ? loginInformationFormData?.password
                      : ""
                  }
                  onPaste={(e) => {
                    setIsPasswordValid(hasValidPassword(e.target.value));
                    setDoPasswordMatch(passwordsMatch(e.target.value, loginInformationFormData.confPassword));
                  }}
                  onChange={(e) => {
                    const newFormData = { ...loginInformationFormData };
                    newFormData.password = e.target.value;
                    setLoginInformationFormData(newFormData);
                    setIsPasswordValid(hasValidPassword(e.target.value));
                    setDoPasswordMatch(passwordsMatch(e.target.value, loginInformationFormData.confPassword));
                    setEnableSubmitButton(isButtonEnabled());
                  }}
                />
              </InputGroup>
              {!isPasswordValid && (
                <Form.Control.Feedback type="invalid">
                  {passwordInvalidMsg}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group as={Col} md="6">
              <Form.Label>Confirm Password</Form.Label>
              <InputGroup>
                <FormControl
                  placeholder="Confirm Password"
                  aria-label="Confirm Password"
                  type={`password`}
                  readOnly={successMsg}
                  onFocus={(e) => (e.target.type = "text")}
                  onBlur={(e) => {e.target.type = "password";}}
                  className="edit-account-input"
                  isInvalid={!doPasswordMatch}
                  value={
                    loginInformationFormData &&
                    loginInformationFormData.confPassword &&
                    loginInformationFormData.confPassword.indexOf(
                      "pbkdf2_sha256$"
                    ) != 0
                      ? loginInformationFormData?.confPassword
                      : ""
                  }
                  onPaste={(e) => {
                    setIsPasswordValid(hasValidPassword(e.target.value));
                    setDoPasswordMatch(passwordsMatch(loginInformationFormData.password, e.target.value));
                  }}
                  onChange={(e) => {
                    const newFormData = { ...loginInformationFormData };
                    newFormData.confPassword = e.target.value;
                    setLoginInformationFormData(newFormData);
                    setIsPasswordValid(hasValidPassword(e.target.value));
                    setDoPasswordMatch(passwordsMatch(loginInformationFormData.password, e.target.value));
                    setEnableSubmitButton(isButtonEnabled());
                  }}
                />
              </InputGroup>
              {!doPasswordMatch && (
                <Form.Control.Feedback type="invalid">
                  {passwordNoMatchMsg}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </Form.Row>
          {getPwRulesJsx()}
          <Button
            className="mt-2"
            variant="danger"
            type="submit"
            disabled={!enableSubmitButton}
            onClick={handleReset}
          >
            Submit
          </Button>
        </Form>
      </div>
    </Jumbotron>
  );
};

PWReset.propTypes = {
  disableSubmitButton: PropTypes.bool,
  accountData: PropTypes.object,
};

export default PWReset;
