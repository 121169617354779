import React from "react";

const LeftSidebar = () => {
  return (
    <div className="left-side-menu">
      <a href="index.html" className="logo text-center logo-light">
        <span className="logo-lg">
          <img src="assets/images/dsp-v-3.svg" className="DSP-v3" alt="" />
        </span>
        <span className="logo-sm">
          <img src="assets/images/dsp-v-3.svg" className="DSP-v3" alt="" />
        </span>
      </a>
      <a href="index.html" className="logo text-center logo-dark">
        <span className="logo-lg">
          <img src="assets/images/dsp-v-3.svg" className="DSP-v3" alt="" />
        </span>
        <span className="logo-sm">
          <img src="assets/images/dsp-v-3.svg" className="DSP-v3" alt="" />
        </span>
      </a>
      <div className="h-100" id="left-side-menu-container" data-simplebar></div>
    </div>
  );
};

export default LeftSidebar;
